<template>
  <v-sheet outlined rounded min-height="100%">
    <!-- {{ companies }} -->
    <Loader v-if="loading" />
    <RefNavigator v-else 
      :items="users_list" 
      :headers="headers" 
      :hasAdditionBtn="true" 
      :hasSearchTextField="true" 
      :hasShowRemovedBtn="true" 
      :hasEditBtns="true"
      @click:row="onClickRowEvent"
      @click:add="onClickAdd"
      @delete="onDelete"
      @restore="onRestore"
    />
    <router-view v-if="!loading"/>
  </v-sheet>
</template>

<script>

import Loader from '../components/ui/Loader.vue'
import RefNavigator from '../components/RefNavigator.vue'
import {mapGetters} from 'vuex'


export default {
  name: 'Users',
  components: {
    Loader, RefNavigator
  },
  data: () => ({
    loading: true
  }),
  async mounted() {
    await Promise.all([
      await this.$store.dispatch('load_users'),
      await this.$store.dispatch('load_companies')
    ])
    this.loading = false
  },
  computed: {
    ...mapGetters(['users', 'companies', ]),

    headers() {
        return [
          {
            text  : '#',
            align : 'start',
            value : 'id'
          },
          {
            text  : 'Имя',
            value : 'name'
          },
          {
            text  : 'Email',
            value : 'email'
          },
          {
            text  : 'Телефон',
            value : 'phone'
          },
          {
            text  : 'Компания',
            value : 'company.name'
          }
        ]
      },
      users_list() {
        let user_list = this.users.map(elem => {
          if (!elem.is_admin) {
            let company = this.companies.find(el => el.id == elem.company_id)
            elem.company = {
              id: company.id,
              name: company.name
            }
          }
          return elem
        })
        return user_list
      }
  },
  methods: {
    async onClickRowEvent(payload) {
      this.$router.push('/users/' + payload.id)
    },
    async onClickAdd(){
      this.$router.push('/users/new')
    },
    async onDelete(item) {
      this.$store.dispatch('delete_user', item) 
    },
    async onRestore(item) {
      this.$store.dispatch('restore_user', item) 
    }
  }
}
</script>